// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@use "libs/global-web/theme/src/lib/theming/theme-marketing/constants.scss" as
  con;
@use "libs/global-web/ui/src/lib/theming.lib" as global-web-ui;
@import "libs/global-web/theme/src/lib/theming/theme-marketing/_marketing";

// Plus imports for other components in your app.

// Include the env styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($spt-typography);`
@include mat.all-legacy-component-typographies($spt-typography);
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$mixer-browser-desktop-primary: mat.define-palette(mat.$indigo-palette);
//$mixer-browser-desktop-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
//$mixer-browser-desktop-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
//$mixer-browser-desktop-theme: mat.define-light-theme($mixer-browser-desktop-primary, $mixer-browser-desktop-accent, $mixer-browser-desktop-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($mixer-browser-desktop-theme);

@include mat.all-legacy-component-themes($marketing-theme);

//@include mat.core-theme($candy-app-theme);
//@include mat.button-theme($candy-app-theme);
//@include mat.checkbox-theme($candy-app-theme);

// keep after color picker so color picker
// overlay has styles from theme overrides
//@import 'libs/web-shared/theme/src/lib/styles/index';
//@include studio-ui-styles($marketing-theme);
//@include spt-material-theme-overrides($dark-theme);
@include global-web-ui.styles();

@import "libs/global-web/theme/src/lib/styles/marketing-ui-styles";
@include marketing-ui-styles(
  $marketing-theme,
  con.$max-width,
  con.$max-width-wide
);
@import "libs/global-web/ui/src/lib/theming.lib";
@include global-web-ui.color($marketing-theme);
@import "libs/global-web/feature-page-subscribe/src/lib/theme.lib";
@include feature-page-subscribe($marketing-theme);
@import "libs/marketing/ui/src/lib/theming.lib";
@include studio-site-ui($marketing-theme);

/* You can add global styles to this file, and also import other style files */
$background: map-get($marketing-theme, background);

html {
  font-size: 16px;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow: hidden;
  color: $marketing-black;
  background: mat.get-color-from-palette($background, background);

  .mat-tab-link {
    min-width: 120px;
  }
}
