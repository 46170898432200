@use "@angular/material" as mat;

@mixin global-styles($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  // COLORS
  .primary {
    color: mat.get-color-from-palette($primary, default);
  }

  .accent {
    color: mat.get-color-from-palette($accent, default);
  }

  .warn {
    color: mat.get-color-from-palette($warn, default);
  }

  .mat-h3 {
    spt-reset-btn,
    spt-help-tooltip {
      top: -2px;
      position: relative;
    }
  }

  //.mat-h3.spt-reset-btn {
  //  top: -2px;
  //  position: relative;
  //}
  //
  //.mat-h3.design-help-tooltip-small {
  //  top: -2px;
  //  position: relative;
  //}

  .header-btn {
    spt-reset-btn,
    spt-help-tooltip {
      margin-left: 10px;
    }
  }

  .spt-disabled {
    color: rgba(255, 255, 255, 0.3);
  }

  // TODO WIT?
  .spt-foreground-color {
    color: mat.get-color-from-palette($foreground, text);

    & .icon-color {
      fill: mat.get-color-from-palette($foreground, text);
    }
  }

  .spt-divider-lighter {
    display: block;
    margin: 0;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: mat.get-color-from-palette($foreground, base);
  }

  a,
  a:hover,
  a:visited,
  a:active {
    color: rgb(228, 228, 228);
  }

  .spt-page-title {
    font-weight: 500;
    font-size: 20px;
    //margin-bottom: 30px;
    //margin-left: 20px;
    //margin-top: 20px;
  }

  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .text-center {
    text-align: center;
  }

  .underline {
    text-decoration: underline;
  }

  .spt-card-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .spt-hand-cursor {
    cursor: pointer;
  }

  .title-text {
    position: relative;
    top: -3px;
    margin-left: 10px;
  }

  .spt-width-100-percent {
    width: 100%;
  }

  .help-bold-accent {
    font-weight: 500;
    color: mat.get-color-from-palette($accent, default);
  }

  .warn-bold {
    font-weight: 500;
    color: mat.get-color-from-palette($warn, default);
  }

  .font-weight-600 {
    font-weight: 600;
  }

  .form-options {
    margin-bottom: 25px;

    mat-checkbox {
      margin-right: 20px;
    }
  }

  .form-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    button:not(:last-child) {
      margin-right: 10px;
    }
  }

  .btn-icon-left {
    padding-left: 8px !important;
  }

  .drag-track-over {
    .spt-tab-content-container {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .photo-url {
    line-height: 0; /* remove line-height */
    display: inline-block; /* circle wraps image */
    border-radius: 50%; /* relative value */
    /*box-shadow: 0px 0px 5px rgba(0,0,0,0.4);*/
    height: 24px;
    width: 24px;

    img {
      border-radius: 50%; /* relative value for
				   adjustable image size */
      height: 24px;
      width: 24px;
    }
  }

  .user-initial {
    line-height: 0; /* remove line-height */
    //display: inline-block; /* circle wraps image */
    border-radius: 50%; /* relative value */
    /*box-shadow: 0px 0px 5px rgba(0,0,0,0.4);*/
    height: 24px;
    width: 24px;
    background-color: mat.get-color-from-palette($primary, default);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .user-initial--letter {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .g-spacer {
    flex: 1 1 auto;
  }

  .studio-dialog-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  $tooltip-color: #4e5b73;

  .g-tooltip {
    background-color: #4e5b73 !important;
    color: white !important;
    &:after {
      border-color: $tooltip-color #0000 #0000 !important;
    }

    &.tooltip-bottom {
      &:after {
        border-color: #0000 #0000 $tooltip-color !important;
      }
    }

    &.tooltip-right {
      &:after {
        border-color: #0000 $tooltip-color #0000 #0000 !important;
      }
    }

    &.tooltip-left {
      &:after {
        border-color: #0000 #0000 #0000 $tooltip-color !important;
      }
    }
  }
}
