@use "@angular/material" as mat;
@import "studio";

@mixin success-message-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  $background-color: map-get(mat.$blue-grey-palette, 700);
  $background-color-mini-fab: mat.get-color-from-palette($accent, 800);
  $header-item-selected: mat.get-color-from-palette($primary, default);

  .success-message {
    color: mat.get-color-from-palette($primary, default);
  }

  @if $is-dark {
    .success-message-container {
      border: 1px solid mat.get-color-from-palette($primary, default);
      border-radius: 4px;
    }

    .success-message {
      color: mat.get-color-from-palette($primary, default);
    }
  }
}
