@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $warn-palette: map.get($color-config, "warn");
  $is-dark-theme: map.get($color-config, "is-dark");
  $background: map.get($color-config, background);
  $foreground: map.get($color-config, foreground);

  .ui-popover-panel {
    @include mat.elevation(4);
    background: mat.get-color-from-palette($background, "card");
  }

  .ui-popover-item {
    background: transparent;
    color: mat.get-color-from-palette($foreground, "text");

    &[disabled] {
      &,
      .ui-popover-subpopover-icon,
      .mat-icon-no-color {
        color: mat.get-color-from-palette($foreground, "disabled");
      }
    }
  }

  .ui-popover-item .mat-icon-no-color,
  .ui-popover-subpopover-icon {
    color: mat.get-color-from-palette($foreground, "icon");
  }

  .ui-popover-item:hover,
  .ui-popover-item.cdk-program-focused,
  .ui-popover-item.cdk-keyboard-focused,
  .ui-popover-item-highlighted {
    &:not([disabled]) {
      background: mat.get-color-from-palette($background, "hover");
    }
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  .ui-popover-item {
    font: {
      family: mat.font-family($typography-config, body-1);
      size: mat.font-size($typography-config, body-1);
      weight: mat.font-weight($typography-config, body-1);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
