@use "@angular/material" as mat;

@mixin pricing-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .trial-header {
    background: mat.get-color-from-palette($accent, default);
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  @if $is-dark == false {
    .trial-header {
    }
  }
}
