$screen-small: 600px;

$default-corner-radius: 4px;
$left-sidenav-link-padding: 10px 16px;
$right-sidenav-link-padding: 2px 16px 2px 16px;

//$track-controls-width: 320px; // with share and sync buttons
//$track-controls-width: 320px; // with delete button

$spt-icon-small: 16px;
$sidenav-font-icon-size: 18px;
$sidenav-text-font-size: 10px;
$sidenav-text-font-weight: 300;

$max-width: 1000px;
$max-width-wide: 1440px;
