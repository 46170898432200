@import "./utilities/utilities";
@import "./global-styles";
@import "../theming/common/_mixins/perfect-scrollbar";
@import "./global/page-layout";

@mixin marketing-ui-styles($theme, $max-width, $max-width-wide) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  @include utilities();
  @include global-styles($theme);

  // END MATERIAL OVERRIDE
  @include perfect-scrollbar($theme);
  @include page-layout-theme($theme, $max-width, $max-width-wide);

  .page-layout-content {
    background: white;
    padding: 20px;

    &--padding {
      @extend .page-layout-content;

      padding: 20px;
    }
  }

  a,
  a:hover,
  a:visited,
  a:active {
    color: map-get($primary, 800);
  }
}
