@import "studio";

@mixin promo-code-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  table.promo-code-table {
    border: 1px solid $spt-dark-opacity;

    tr:not(:last-child) {
      td {
        border-bottom: 1px solid $spt-dark-opacity;
      }
    }

    td:not(:last-child) {
      border-right: 1px solid $spt-dark-opacity;
    }
  }

  .promo-code-table {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    border: 1px solid $spt-dark-opacity;

    .flex-tr {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;

      .flex-td {
        &.title {
          width: 100px;
          font-weight: 500;
        }

        &.value {
          display: flex;
          flex-grow: 1;
        }
      }
    }

    .flex-tr:not(:last-child) {
      .flex-td {
        border-bottom: 1px solid $spt-dark-opacity;
      }
    }

    .flex-td:not(:last-child) {
      border-right: 1px solid $spt-dark-opacity;
    }
  }

  @if $is-dark {
    table.promo-code-table {
      border: 1px solid $spt-light-opacity;

      tr:not(:last-child) {
        td {
          border-bottom: 1px solid $spt-light-opacity;
        }
      }

      td:not(:last-child) {
        border-right: 1px solid $spt-light-opacity;
      }
    }

    .promo-code-table {
      border: 1px solid $spt-light-opacity;

      .flex-tr:not(:last-child) {
        .flex-td {
          border-bottom: 1px solid $spt-light-opacity;
        }
      }

      .flex-td:not(:last-child) {
        border-right: 1px solid $spt-light-opacity;
      }
    }
  }
}
