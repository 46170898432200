@use "@angular/material" as mat;

@mixin payment-wizard-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  $border-color: #e5e5e5;

  .payment-wizard {
    .title,
    mat-card-title {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.10000000149011612px;
      color: mat.get-color-from-palette($primary, default);
    }

    .trial-disclaimer {
      color: mat.get-color-from-palette($accent, default);
      margin-top: 20px;
      font-weight: 400;
    }

    .price-tile-container {
      .price-tile {
        .price-trial-header {
          color: mat.get-color-from-palette($accent, default);
        }
      }
    }
  }

  .total-cost {
  display: block;
  text-align: right;
    color: mat.get-color-from-palette($primary, default);
  }

  .example-radio-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0;

    mat-radio-button {
      margin-bottom: 20px;
    }
  }

  .price-tile-container {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: stretch;

    .price-tile {
      display: flex;
      flex-basis: 43%; /* or - flex: 0 50% - or - flex-basis: 50% - */
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 10px;
      border: 1px solid $border-color;
      min-width: 140px;
      //height: 130px;
      overflow: hidden;
      //margin-right: 20px;
      margin-bottom: 20px;

      &--no-border {
        @extend .price-tile;
        border: none;
      }

      .subheading-2 {
        position: relative;
        top: -18px;
      }

      .price-trial-header {
        font-weight: 500;
      }

      .price-trial-price {
        font-size: 12px;
        font-weight: 400;
      }

      //.plan-title {
      //  font-weight: 500;
      //  padding-bottom: 10px;
      //}
    }
  }

  @if $is-dark == false {
    .payment-wizard {
    }
  }
}
