@use "sass:map";
@use "@angular/material" as mat;
@use "../core/chart.variables" as var;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $warn-palette: map.get($color-config, "warn");
  $is-dark-theme: map.get($color-config, "is-dark");

  // class set in component ts file
  // @HostBinding('class.login-component') bind = true;
  // hsla(0,0%,100%,.3)
  .t-ui-bullet-chart {
    .t-bullet-background-bar {
      fill: mat.get-color-from-palette($primary-palette, 90);
      opacity: 0.25;
    }
    .t-bullet-progress-bar {
      fill: mat.get-color-from-palette($primary-palette, 500);
    }

    .t-bullet-progress-indicator {
      fill: mat.get-color-from-palette($accent-palette, 500);
    }

    .t-bullet-chart-limit {
      fill: mat.get-color-from-palette($primary-palette, 500);

      // Used in d3 typescript "t-bullet-chart-limit--left"
      &--left {
        @extend .t-bullet-chart-limit;
      }

      // Used in d3 typescript "t-bullet-chart-limit--right"
      &--right {
        @extend .t-bullet-chart-limit;
      }
    }
  }

  @if ($is-dark-theme) {
    .t-ui-bullet-chart {
      .t-bullet-background-bar {
        fill: var.$background-light;
        opacity: 0.25;
      }
    }
  }
}
