@use "sass:map";
@use "@angular/material" as mat;
@use "./core/chart.variables" as var;

@mixin styles() {
  .g-ui-chart-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $warn-palette: map.get($color-config, "warn");
  $is-dark-theme: map.get($color-config, "is-dark");

  $tooltip-border: mat.get-color-from-palette($accent-palette, 500);

  // class set in component ts file
  // @HostBinding('class.login-component') bind = true;
  .g-ui-chart-container {
  }

  .g-chart-tooltip {
    border: 1px solid $tooltip-border;
    padding: 10px;
    margin-bottom: 15px;
    color: mat.get-color-from-palette($primary-palette, 500);
    background-color: white;
  }

  .g-chart-tooltip-reversed {
    .g-chart-tooltip {
      background-color: $tooltip-border;
      color: mat.get-contrast-color-from-palette($primary-palette, 500);
    }

    .g-chart-tooltip-divot:before {
      background: $tooltip-border;
    }
  }

  .g-chart-tooltip-divot:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    background: white;
    border: 1px solid $tooltip-border;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: translate(-50%, 50%) rotate(45deg);
    transform-origin: center center;
    z-index: 10;
  }

  @if ($is-dark-theme) {
    .g-chart-tooltip {
      background-color: var.$background-color;
    }
    .g-chart-tooltip-divot:before {
      background-color: var.$background-color;
    }
  }
}
