// https://color.adobe.com/create/color-wheel
// Foreground
$marketing-divider: #313c4e;

// Background original
//$marketing-black: #212936;
//$marketing-black-light: #2b3648;
//$marketing-light: #56657f;

$marketing-black: #191f29;
$marketing-black-light: #232c3b;
$marketing-light: #4e5b73;

// #303030
$background: $marketing-black-light;
// #424242
$card: $marketing-black;
// #212121
$app-bar: $marketing-black;
// #616161
$unselected-chip: $marketing-light;

// Dark White Extra
$marketing-white-extra: #afbdd1;
$marketing-white-extra-lighter: #c2d1e7;
// Dark White
$marketing-white: #8997b1;

$modals-scrim-black-dark: rgba($marketing-black, 0.54);

$spt-border-color: rgba(255, 255, 255, 0.12);

$default-border-color: rgba(255, 255, 255, 0.1);
$default-background-color: rgb(19, 21, 24);
$spt-dark-label-color: rgba(255, 255, 255, 0.7);
$marketing-faded-title: rgba(255, 255, 255, 0.6);

// TODO Refactor to his

$spt-border: 1px solid $spt-border-color;
$spt-border-dashed: 2px dashed rgba(255, 255, 255, 0.12);
$border-1-color: #3c3c3c;
