@mixin page-layout-class($max-width, $max-width-wide) {
  .page-layout {
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    &--wide {
      @extend .page-layout;

      max-width: $max-width-wide;
      width: 100%;
      position: relative;
      display: flex;
      flex: 2;

      .page-layout-content {
        background: white;
        display: flex;
        flex: 2;
        //width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        position: relative;

        &--padding {
          @extend .page-layout-content;
          padding: 20px;
        }
      }
    }
  }
}

@mixin page-layout-theme($theme, $max-width, $max-width-wide) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .max-width {
    max-width: $max-width;

    &--wide {
      max-width: $max-width-wide;

      @include page-layout-class($max-width, $max-width-wide);
    }
  }

  @include page-layout-class($max-width, $max-width-wide);
}
