@use "@angular/material" as mat;
@import "./success-message/success-message.component.theme";
@import "./warn-message/warn-message.component.theme";
@import "studio";

@mixin message-box-theme($theme) {
  @include success-message-theme($theme);
  @include warn-message-theme($theme);

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  $background-color: map-get(mat.$blue-grey-palette, 700);
  $background-color-mini-fab: mat.get-color-from-palette($accent, 800);
  $header-item-selected: mat.get-color-from-palette($primary, default);

  @if $is-dark {
  }

  .message {
    text-align: left;
    font-weight: 400;
    line-height: 1.5;
    color: mat.get-color-from-palette($warn, default);

    &.collapsed {
      visibility: hidden;
      opacity: 0;
      max-height: 1px;
      margin-bottom: 0;
      padding: 0;
      transition: visibility 500ms, opacity 500ms, max-height 0.28s;
      transition-timing-function: ease-out;
    }

    &.expanded {
      visibility: visible;
      opacity: 1;
      max-height: 4000px;
      margin-bottom: 24px;
      padding: 8px;
      transition: visibility 0.5s, opacity 0.5s, max-height 0.5s;
      transition-timing-function: ease-in-out;
    }
  }
}
